export enum DocumentType {
  TERMS_AND_CONDITIONS = 'TERMINOS_Y_CONDICIONES',
  PRIVACY_POLICY = 'POLITICAS_DE_PRIVACIDAD',
  TERMS_AND_CONDITIONS_REPRESENTATIVE = 'TERMINOS_Y_CONDICIONES_REPRESENTANTE',
  TERMS_AND_CONDITIONS_INVESTMENTS = 'TERMINOS_Y_CONDICIONES_INVERSIONES'
}

export interface LegalDocument {
  id: number
  documentType: DocumentType
  fullDocumentURL: string
  summaryDocumentURL: string
  validityDate: Date
  isActive: boolean
  createdAt: Date
  updatedAt: Date
}

export interface LegalDocumentState {
  acceptedLegalDocuments: LegalDocument[]
  pendingLegalDocuments: LegalDocument[]
  loading: boolean
  loadingPending: boolean
  loadingAccepted: boolean
  error?: undefined
}

export enum LegalDocumentActionTypes {
  GET_ACCEPTED_LEGAL_DOCUMENTS_FULFILLED = '@LEGAL_DOCUMENT:GET_ACCEPTED_LEGAL_DOCUMENTS_FULFILLED',
  GET_ACCEPTED_LEGAL_DOCUMENTS_PENDING = '@LEGAL_DOCUMENT:GET_ACCEPTED_LEGAL_DOCUMENTS_PENDING',
  GET_ACCEPTED_LEGAL_DOCUMENTS_REJECTED = '@LEGAL_DOCUMENT:GET_ACCEPTED_LEGAL_DOCUMENTS_REJECTED',
  GET_PENDING_LEGAL_DOCUMENTS_FULFILLED = '@LEGAL_DOCUMENT:GET_PENDING_LEGAL_DOCUMENTS_FULFILLED',
  GET_PENDING_LEGAL_DOCUMENTS_PENDING = '@LEGAL_DOCUMENT:GET_PENDING_LEGAL_DOCUMENTS_PENDING',
  GET_PENDING_LEGAL_DOCUMENTS_REJECTED = '@LEGAL_DOCUMENT:GET_PENDING_LEGAL_DOCUMENTS_REJECTED',
  ACCEPT_LEGAL_DOCUMENT_FULFILLED = '@LEGAL_DOCUMENT:ACCEPT_LEGAL_DOCUMENT_FULFILLED',
  ACCEPT_LEGAL_DOCUMENT_PENDING = '@LEGAL_DOCUMENT:ACCEPT_LEGAL_DOCUMENT_PENDING',
  ACCEPT_LEGAL_DOCUMENT_REJECTED = '@LEGAL_DOCUMENT:ACCEPT_LEGAL_DOCUMENT_REJECTED'
}
