import { DocumentType } from 'store/legal-document/types'

export const DocumentTypes = {
  [DocumentType.TERMS_AND_CONDITIONS]: 'Términos y Condiciones',
  [DocumentType.PRIVACY_POLICY]: 'Políticas de Privacidad',
  [DocumentType.TERMS_AND_CONDITIONS_REPRESENTATIVE]:
    'Términos y Condiciones (Representante)',
  [DocumentType.TERMS_AND_CONDITIONS_INVESTMENTS]:
    'Términos y Condiciones (Inversiones)'
}

export enum ModalDescription {
  MANDATORY_DOCUMENTS = `Hemos actualizado los siguientes documentos legales.
  Para poder seguir usando la aplicación, deberá dar consentimiento explícito.`,
  OPTIONAL_DOCUMENTS = `Hemos actualizado los siguientes documentos legales.
  Para poder seguir usando la aplicación, deberá dar consentimiento en cada uno.`,
}
