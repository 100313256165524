import React, { useCallback } from 'react'
import useNavigation from 'hooks/useNavigation'
import { setShowDrawerSuccess } from 'store/modals/actions'
import { showDrawer as showDrawerAction } from 'store/drawer/actions'
import { useDispatch } from 'react-redux'
import {
  Drawer,
  Container,
  BackArrowButton,
  ButtonStyled,
  Title,
  Body,
  ContentContainer,
  Header,
  SuccessTick
} from './style'
import BackArrow from 'assets/svg/BackArrow'
import { useAppSelector } from 'hooks/redux'
import ButtonLine from 'components/atoms/ButtonLine'
import { Box } from '@chakra-ui/react'
import { Drawers } from 'store/drawer/types'

const DrawerSuccess: React.FC = () => {
  const { showDrawerSuccess } = useAppSelector(state => state.modal)
  const { title, body } = showDrawerSuccess
  const { movements, isLoading } = useAppSelector(state => state.transfers)
  const dispatch = useDispatch()
  const { onNavigate } = useNavigation()

  const onClose = useCallback(() => {
    dispatch(setShowDrawerSuccess(false, true, ''))
    onNavigate(showDrawerSuccess?.navigation?.path, {
      title: showDrawerSuccess?.navigation?.title
    })
  }, [dispatch, onNavigate, showDrawerSuccess])

  const onPressBackToHome = useCallback(() => {
    dispatch(setShowDrawerSuccess(false, true, ''))
    onNavigate('/content/wallet')
  }, [dispatch, onNavigate])

  const onPressReceipt = useCallback(() => {
    const drawerInfo = {
      date: movements[0]?.timestamp,
      amount: movements[0]?.amount,
      cbu: movements[0]?.cbu || '',
      cuit: movements[0]?.cuit,
      id: movements[0]?.id,
      owner: movements[0]?.owner,
      movementType: movements[0]?.movementType,
      status: movements[0]?.status
    }
    dispatch(setShowDrawerSuccess(false, true, ''))
    dispatch(showDrawerAction(Drawers.RECEIPT_DETAIL, drawerInfo))
  }, [dispatch, movements])

  const renderList = () => {
    if (showDrawerSuccess.navigation?.fromScreen === 'transfer') {
      return (
        <Box display="flex" flexDir="column" width="100%">
          <ButtonLine onPress={onPressBackToHome} marginBottom={20}>
            Volver al inicio
          </ButtonLine>
          <ButtonStyled onPress={onPressReceipt} disabled={isLoading} loading={isLoading}>
            Ver comprobante
          </ButtonStyled>
        </Box>
      )
    }
    if (showDrawerSuccess.navigation?.fromScreen === 'myContacts') {
      return (
        <ButtonLine onPress={onClose} disabled={false} marginBottom={40}>
          Volver al inicio
        </ButtonLine>
      )
    }
    return (
      <ButtonStyled onPress={onClose} disabled={false}>
        Continuar
      </ButtonStyled>
    )
  }

  return (
    <Drawer open={showDrawerSuccess?.show} onClose={onClose} direction="right">
      <Container>
        <Header>
          <BackArrowButton type="button" onClick={onClose}>
            <BackArrow />
          </BackArrowButton>
        </Header>
        <ContentContainer>
          <SuccessTick />
          <Title>{title}</Title>
          {body && <Body>{body}</Body>}
        </ContentContainer>
        {renderList()}
      </Container>
    </Drawer>
  )
}

export default DrawerSuccess
