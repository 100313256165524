import React, { useCallback, useEffect, useState } from 'react'
import CustomText from 'components/atoms/CustomText'
import { format2Digits } from '@qirapagos/lib/utils/common'
import { ButtonLine, TwoFactorAuthenticatorInput } from 'components'
import { useAppSelector, useAppThunkDispatch } from 'hooks/redux'
import { getTwoFactorAuthCode, validateTwoFactorCode, getMyData } from 'store/auth/thunks'
import { setShowDrawerError, setShowDrawerSuccess } from 'store/modals/actions'
import { title, bodyDrawer, navigation } from 'screens/Home/YourAccount/MyContacts/StepTwoAdd/constants'
import {
  getTransfers,
  transferToAccount,
  newContact
} from 'store/transfers/thunks'
import { Box } from '@chakra-ui/react'
import { styles, Container2FA, Footer, ButtonStyled } from './styles'
interface Props {
  index: number
  titlesInfo?: any
  fromContacts?: boolean
}
const StepFive: React.FC<Props> = ({ index, titlesInfo, fromContacts }) => {
  const dispatch = useAppThunkDispatch()
  const [value, setValue] = useState<string>('')
  const [text, setText] = useState('Reenviar código')
  const [timer, setTimer] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [countDownToZero, setCountDownToZero] = useState(false)
  const { myData } = useAppSelector(state => state.auth)
  const { isLoading } = useAppSelector(state => state.auth)
  const { isLoadingCreateTransfer, isLoading: loading } = useAppSelector(
    state => state.transfers
  )

  useEffect(() => {
    if (!myData?.email) {
      dispatch(getMyData())
    }
    if (index === 4) {
      dispatch(getTwoFactorAuthCode(2))
    }
    if (index === 2 && fromContacts) {
      dispatch(getTwoFactorAuthCode(2))
    }
  }, [titlesInfo, dispatch, myData?.email, index])

  useEffect(() => {
    if (value.length !== 6) {
      setHasError(false)
    }
  }, [value, index])

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let counter: NodeJS.Timeout
    let seconds = 60
    if (timer) {
      setText('Código reenviado')
      setCountDownToZero(true)
      const countDown = () => {
        counter = setInterval(() => {
          seconds -= 1
          setText(`Volver a intentar en 00:${format2Digits(seconds)}`)
          if (seconds <= 0) {
            setCountDownToZero(false)
            setTimer(false)
            setText('Reenviar código')
          }
        }, 1000)
      }
      setTimeout(countDown, 500)

      return () => {
        if (timer) {
          clearInterval(counter)
        }
      }
    }
  }, [timer])

  const sendTokenToEmail = useCallback(() => {
    setTimer(true)
    dispatch(getTwoFactorAuthCode(1))
  }, [dispatch])

  const sendTokenToSMS = useCallback(() => {
    setTimer(true)
    dispatch(getTwoFactorAuthCode(2))
  }, [dispatch])

  const onPress = useCallback(() => {
    if (fromContacts) {
      dispatch(validateTwoFactorCode(value, setHasError)).then((response) => {
        if (response?.success) {
          dispatch(
            newContact(
              titlesInfo[1].dataSecondStep,
              titlesInfo[1].dataSecondStep.contactId
            )
          )
            .then(() => {
              dispatch(
                setShowDrawerSuccess(true, true, title.success, bodyDrawer.success, navigation)
              )
            })
            .catch(() => {
              dispatch(
                setShowDrawerError(true, title.error, bodyDrawer.error, navigation)
              )
            })
        }
      }
      )
      return
    }

    dispatch(validateTwoFactorCode(value, setHasError)).then(
      (response) => {
        if (response?.success) {
          dispatch(transferToAccount(response.validationId, titlesInfo[1]?.dataSecondStep?.observations))
            .then((response) => {
              if (response) {
                dispatch(getTransfers(1))
              }
            })
        }
      }
    )
  }, [dispatch, value])

  return (
    <Container2FA>
      <TwoFactorAuthenticatorInput
        value={value}
        setValue={setValue}
        error={hasError}
      />
      {hasError && (
        <CustomText size="medium" color="red" customStyle={styles.spacing}>
          El PIN ingresado es incorrecto.
        </CustomText>
      )}
      <Footer>
        {!countDownToZero
          ? (
            <Box
              display="flex"
              flexDir="row"
              justifyContent="space-around"
              w="50%"
              gap="50px"
            >
              <ButtonLine onPress={sendTokenToSMS}>{text}</ButtonLine>
              <ButtonLine onPress={sendTokenToEmail}>
                Enviar por e-mail
              </ButtonLine>
            </Box>
            )
          : (
            <div>
              <CustomText color="purple" size="big" customStyle={styles.text}>
                {text}
              </CustomText>
            </div>
            )}
      </Footer>
      <ButtonStyled
        onPress={onPress}
        loading={isLoading || isLoadingCreateTransfer || loading}
        id='continueCode'
      >
        Continuar
      </ButtonStyled>
    </Container2FA>
  )
}

export default StepFive

StepFive.defaultProps = {
  titlesInfo: null,
  fromContacts: false
}
