import * as Sentry from '@sentry/react'
import { IbodyPhone } from '@qirapagos/lib/interfaces/reduxInterfaces'
import * as AuthActions from '@qirapagos/lib/store/auth/actions'
import * as AuthActionsV2 from 'store/auth/actions'
import { AuthServices } from 'services'
import { ApiResponse } from 'apisauce'
import {
  ErrorMessages,
  getDemoStatus,
  isCuitValid
} from '@qirapagos/lib/utils/common'
import { getToken, resetAll } from 'services/utils'
import { AuthActionTypes, IBodyAddress } from '@qirapagos/lib/store/auth/types'
import { TransfersActionTypes } from '@qirapagos/lib/store/transfers/types'
import { SCREEN_TITLES } from 'utils/navigation'
import { ContractActionTypes } from '@qirapagos/lib/store/contracts/types'
import { errorMessagesByCode } from 'constants/errors'
import { MenuActionTypes } from 'store/menu/types'
import handleAnalyticsEvent from 'utils/analytics'
import { ANALYTICS_EVENTS } from 'utils/analytics/events'
import { LOGOUT } from 'store/modals/types'
import * as transfersActions from 'store/transfers/actions'
import { ActivityActionTypes } from 'store/activity/types'
import { getFirebaseToken } from 'config/firebase'
import { ErrorCodes } from './types'
import { ThunkDispatchType } from 'types/redux'

export const loginUserWithEnterprise =
  (user: any, enterprise: any, onNavigate: any) =>
    async (dispatch: ThunkDispatchType) => {
      try {
        dispatch(AuthActions.setAuthLoading())
        const { isBusiness } = isCuitValid(enterprise?.cuit)
        const status = {
          companyStatus: enterprise.companyStatus,
          onboardingStatus: enterprise.onboardingStatus
        }
        if (enterprise?.userId) {
          localStorage.setItem('userId', String(enterprise?.userId))
        }
        await Promise.all([
          localStorage.setItem('token', enterprise?.token),
          localStorage.setItem('clientId', enterprise?.idclient?.toString()),
          localStorage.setItem('companyId', enterprise?.companyId?.toString()),
          localStorage.setItem('hasSisa', enterprise.sisa.toString()),
          user && localStorage.setItem('email', user.user),
          localStorage.setItem('isBusiness', String(isBusiness)),
          localStorage.setItem('businessName', enterprise?.businessName),
          localStorage.setItem('cuit', enterprise?.cuit),
          localStorage.setItem('dni', enterprise?.dni || ''),
          localStorage.setItem('userLoggedIn', 'true'),
          localStorage.setItem('docs', String(enterprise?.docs)),

          localStorage.setItem(
            'isMainAccount',
            String(enterprise?.isMainAccount)
          ),
          localStorage.setItem('status', JSON.stringify(status)),
          localStorage.setItem('companyStatus', String(enterprise.companyStatus)),
          localStorage.setItem(
            'onboardingStatus',
            String(enterprise.onboardingStatus)
          ),
          localStorage.setItem(
            'accountOpeningDate',
            enterprise.accountOpeningDate
          )
        ])
        const userStatus = JSON.stringify(status)
        const { companyStatus, onboardingStatus } = JSON.parse(userStatus || '')
        const finalStatus = getDemoStatus(onboardingStatus, companyStatus)
        dispatch(
          AuthActions.setRegisterUser({
            cuit: enterprise?.cuit,
            name: enterprise?.businessName,
            password: '',
            email: user.user,
            dni: enterprise?.dni,
            code: ''
          })
        )
        dispatch(AuthActions.setBusinessName(enterprise.businessName))
        dispatch(AuthActions.setIsBusiness(isBusiness))
        dispatch(AuthActions.setUserStatus(finalStatus))
        dispatch(AuthActions.dismissAuthLoading())
        Sentry.setExtras({
          data: {
            enterprise
          }
        })
        // if (finalStatus !== 'APROVED') {
        //   handleAnalyticsEvent(ANALYTICS_EVENTS.LOGIN_USER_NOT_APPROVED, {
        //     email: user.user,
        //     client: enterprise.idclient
        //   })
        //   dispatch(setShowModal('WEBinvalidUser'))
        //   dispatch(AuthActions.dismissAuthLoading())
        //   return
        // }
        onNavigate('/content/wallet')
      } catch (error) {
        Sentry.captureMessage(
          `Error en loginUserWithEnterprise: ${error}`,
          'error'
        )
        dispatch(AuthActions.setAuthError())
      }
    }

export const loginUser =
  (user: any, onNavigate: any, setSubmitError: any) =>
    async (dispatch: ThunkDispatchType) => {
      try {
        dispatch(AuthActions.setAuthLoading())
        const device = await localStorage.getItem('device')
        const deviceToken = await localStorage.getItem('deviceToken')
        const keyEndpoints = await localStorage.getItem('keyendpoints')
        const response: any = await AuthServices.loginMulti(
          user,
          device,
          deviceToken,
          keyEndpoints as string,
          await getFirebaseToken() as string
        )
        Sentry.setExtras({
          data: {
            response: response.data.data,
            device,
            deviceToken
          }
        })
        if (response.data.code === ErrorCodes.PASSWORD_LOCKED) {
          throw ErrorMessages.USER_BLOCKED
        }
        if (response.data.code === ErrorCodes.PASSWORD_EXPIRED) {
          onNavigate('/auth/forgot-password/', { isPasswordExpired: true })
          throw ErrorMessages.USER_EXPIRED
        }
        if (response.data.code === ErrorCodes.USER_SUSPENDED) {
          throw ErrorMessages.USER_SUSPENDED
        }
        if (response?.status >= 400) {
          throw ErrorMessages.LOGIN_VALIDATION_ERROR
        }

        const mainAccount = response.data.data[0]
        await Promise.all([
          localStorage.setItem('allEnterprises', JSON.stringify(response.data.data)),
          localStorage.setItem('loginId', mainAccount?.loginId?.toString())
        ])
        dispatch(AuthActions.setMultiEnterprises(response.data.data))
        dispatch(loginUserWithEnterprise(user, mainAccount, onNavigate))
        dispatch(createLoginLog())
        dispatch(AuthActions.dismissAuthLoading())
        handleAnalyticsEvent(ANALYTICS_EVENTS.LOGIN_SUCCESS, {
          email: user.user
        })
        sessionStorage.setItem('isLogged', 'true')
      } catch (error) {
        handleAnalyticsEvent(ANALYTICS_EVENTS.LOGIN_FAILURE, {
          email: user.user
        })
        Sentry.captureMessage(`Error al loguearse:${error}`, 'info')
        setSubmitError(error)
        dispatch(AuthActions.setAuthError())
      }
    }

export const logOut =
  (navigation: any) => async (dispatch: ThunkDispatchType) => {
    try {
      await resetAll()
      dispatch({ type: AuthActionTypes.LOGOUT })
      dispatch({ type: TransfersActionTypes.LOGOUT })
      dispatch({ type: ContractActionTypes.LOGOUT })
      dispatch({ type: ActivityActionTypes.LOGOUT })
      dispatch({ type: MenuActionTypes.LOGOUT })
      dispatch({ type: LOGOUT })
      navigation(`/auth/${SCREEN_TITLES.LOGIN}`)
    } catch (error) {
      dispatch(AuthActions.setAuthError())
    }
  }

export const getTwoFactorAuthCode = (idChannel: number) => async () => {
  try {
    const response: any = await AuthServices.twoFactorAuthentication(idChannel)
    if (response.status !== 200) {
      throw new Error(ErrorMessages.DEFAULT_BACKEND_ERROR)
    }
    await localStorage.setItem('twoFactorAuthCode', response.data.data.token)
  } catch (error) {
    Sentry.captureMessage(`Error al obtener código de autenticación:${error}`)
  }
}

export const forgotPasswordAsk = (email: string) =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(AuthActions.setAuthLoading())
      const response: any = await AuthServices.forgotPasswordAsk(email)
      if (response.status !== 200) {
        throw new Error(errorMessagesByCode(response.data.code))
      }
      return Promise.resolve()
    } catch (error: any) {
      Sentry.captureMessage(`Error al enviar código de cambio de contraseña: ${error}`, 'error')
      dispatch(AuthActions.setNewPasswordError(error.message))
      return Promise.reject(error?.message)
    } finally {
      dispatch(AuthActions.dismissAuthLoading())
    }
  }

export const forgotPasswordValidate = (token: string) =>
  async (dispatch: ThunkDispatchType) => {
    try {
      localStorage.removeItem('clientId')
      dispatch(AuthActions.setValidateRecoverPasswordLoading(true))
      const response: any = await AuthServices.forgotPasswordValidate(token)
      if (response.status !== 200) {
        throw new Error(errorMessagesByCode(response.data.code))
      }
      localStorage.setItem('clientId', response.data.data.id)
      return Promise.resolve()
    } catch (error: any) {
      Sentry.captureMessage(`Error al validar el token de cambio de contraseña: ${error}`, 'error')
      dispatch(AuthActions.setRecoverPasswordError(error.message))
      return Promise.reject(error?.message)
    } finally {
      dispatch(AuthActions.setValidateRecoverPasswordLoading(false))
    }
  }

export const forgotPasswordRecover = (password: string, token: string) =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(AuthActions.setAuthLoading())
      const response: any = await AuthServices.forgotPasswordRecover(password, token)
      if (response.status !== 200) {
        throw response.data
      }
      return Promise.resolve()
    } catch (error: any) {
      Sentry.captureMessage(`Error cambiar la contraseña: ${error}`, 'error')
      return Promise.reject(error?.code)
    } finally {
      dispatch(AuthActions.dismissAuthLoading())
    }
  }

export const requestTwoFactorCode =
  (channel: number, email?: string, phone?: string) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(AuthActions.setAuthLoading())
      const response: any = await AuthServices.twoFactorAuthentication(
        channel,
        email,
        phone
      )
      if (response.status !== 200) {
        throw new Error(ErrorMessages.DEFAULT_BACKEND_ERROR)
      }
      localStorage.setItem('twoFactorAuthCode', response.data.data.token)
      dispatch(AuthActions.dismissAuthLoading())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Sentry.captureMessage(
        `Error al enviar código de cambio de contraseña: ${error}`,
        'error'
      )
      dispatch(AuthActions.dismissAuthLoading())
      throw error?.toString()
    }
  }

export const verifyTwoFactorCode =
  (code: string) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(AuthActions.setAuthLoading())
      const response: any = await AuthServices.twoFactorValidation(code)
      Sentry.setExtras({ data: { response: response.data } })
      if (response.status !== 200) {
        throw new Error(ErrorMessages.DEFAULT_BACKEND_ERROR)
      }
      dispatch(AuthActions.dismissAuthLoading())
      dispatch(AuthActions.setTwoFactorVerified(true))
      return response.data.data.success
    } catch (error: any) {
      Sentry.captureMessage(`Error al validar 2FA:${error}`)
      dispatch(AuthActions.dismissAuthLoading())
      return false
    }
  }

export const updatePassword = (oldPassword: string, newPassword: string) =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(AuthActions.setAuthLoading())
      const response: any = await AuthServices.newPassword(oldPassword, newPassword)
      if (response.status !== 200) {
        throw response.data
      }
      return Promise.resolve()
    } catch (error: any) {
      Sentry.captureMessage(`Error cambiar la contraseña: ${error}`, 'error')
      dispatch(AuthActions.setNewPasswordError(error))
      return Promise.reject(error)
    } finally {
      dispatch(AuthActions.dismissAuthLoading())
    }
  }

export const validateTwoFactorCode =
  (code: string, setHasError?: (error: boolean) => void) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(AuthActions.setAuthLoading())
      const token = await getToken()
      const response = await AuthServices.twoFactorValidation(code)
      Sentry.setExtras({
        data: {
          response: response.data,
          token
        }
      })
      if (response.status !== 200 || !response.data) {
        setHasError?.(true)
        throw new Error(ErrorMessages.DEFAULT_BACKEND_ERROR)
      }
      localStorage.setItem(
        'validationTwoFactor',
        response.data.data.success.toString()
      )
      dispatch(AuthActions.dismissAuthLoading())
      return response.data.data
    } catch (error) {
      Sentry.captureMessage(`Error al validar 2FA:${error}`)
      dispatch(AuthActions.dismissAuthLoading())
    }
  }

export const unsubscribeCompany =
  (reason: string) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(AuthActions.setAuthLoading())
      const response: any = await AuthServices.unsubscribeCompany(reason)
      if (response.status !== 200) {
        throw ErrorMessages.DEFAULT_REQUEST_ERROR
      }
      dispatch(AuthActions.dismissAuthLoading())
    } catch (error: any) {
      dispatch(AuthActions.dismissAuthLoading())
      throw error?.toString()
    }
  }

export const suspendCompany: any =
  (reason: string) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(AuthActions.setAuthLoading())
      const response: any = await AuthServices.suspendCompany(reason)
      if (response.status !== 200) {
        throw ErrorMessages.ERROR_SUSPEND
      }
      dispatch(AuthActions.dismissAuthLoading())
      return response
    } catch (error: any) {
      dispatch(AuthActions.dismissAuthLoading())
      return {
        error: true,
        message: error
      }
    }
  }

export const changeEmail =
  (email: string) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(AuthActions.setEmailPending())
      const response: any = await AuthServices.changeEmail(email)
      if (response.status !== 200) {
        if (response.data.error_code === 100) {
          throw ErrorMessages.EMAIL_USED
        }
        throw ErrorMessages.EMAIL_EDIT_ERROR
      }

      dispatch(AuthActions.setEmailFulfilled(response.data.email))
    } catch (error: any) {
      dispatch(AuthActions.setEmailRejected())
      throw error?.toString()
    }
  }

export const changePhone =
  (bodyPhone: IbodyPhone) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(AuthActions.setPhonePending())
      const response: any = await AuthServices.changePhone(bodyPhone)
      if (response.status !== 200) {
        if (response.data.error_code === 101) {
          throw ErrorMessages.PHONE_USED
        }
        throw ErrorMessages.PHONE_EDIT_ERROR
      }
      dispatch(AuthActions.setPhoneFulfilled(response.data.phone))
    } catch (error: any) {
      dispatch(AuthActions.setPhoneRejected())
      throw error?.toString()
    }
  }

export const createLoginLog = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(AuthActions.setAuthLoading())
    const response: any = await AuthServices.createLoginLog()
    if (response.status !== 200) {
      throw ErrorMessages.DEFAULT_BACKEND_ERROR
    }
  } catch (error: any) {
    throw error?.toString()
  } finally {
    dispatch(AuthActions.dismissAuthLoading())
  }
}

export const readLastLogin = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(AuthActions.setAuthLoading())
    const response: any = await AuthServices.readLastLogin()
    if (response.status !== 200) {
      throw ErrorMessages.DEFAULT_BACKEND_ERROR
    }
    dispatch(AuthActions.setLastLogin(response.data.data))
  } catch (error: any) {
    throw error?.toString()
  } finally {
    dispatch(AuthActions.dismissAuthLoading())
  }
}

export const changeAlias =
  (alias: string) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(AuthActions.setAuthLoading())
      const response: any = await AuthServices.changeAlias(alias)
      if (response.status !== 200) {
        throw errorMessagesByCode(response.data.code)
      }
      dispatch(transfersActions.updateAlias(alias))
    } catch (error: any) {
      throw error?.toString()
    } finally {
      dispatch(AuthActions.dismissAuthLoading())
    }
  }

export const getPrivacyPolicies = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(AuthActions.getPrivacyPoliciesPending())
    const response: any = await AuthServices.privacyPolicies()
    if (response.data.error) {
      throw new Error(response.data.message)
    }

    const linkSource = response.data.data

    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = 'QiraPagos - Políticas de Privacidad.pdf'
    downloadLink.click()
    downloadLink.remove()

    dispatch(AuthActions.getPrivacyPoliciesFulfilled())
  } catch (error: any) {
    dispatch(AuthActions.getPrivacyPoliciesRejected(error instanceof Error
      ? error.message
      : error?.toString?.()))
  }
}

export const getMyData = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch(AuthActions.setMyDataPending())
    const token = await getToken()
    const clientId = await localStorage.getItem('clientId')
    const keyEndpoints = await localStorage.getItem('keyendpoints')
    const response: ApiResponse<any> = await AuthServices.getMyData(
      clientId,
      token,
      keyEndpoints as string
    )
    Sentry.setExtras({
      data: {
        response: response.data,
        token,
        clientId
      }
    })
    dispatch(AuthActions.setMyDataFulfilled(response.data))
  } catch (error) {
    Sentry.captureMessage(`Error al conseguir mis datos:${error}`, 'error')
    dispatch(AuthActions.setMyDataRejected())
  }
}

export const changeAddress =
  (values: IBodyAddress) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(AuthActions.changeAddressPending())
      const response: ApiResponse<any> = await AuthServices.changeAddress(values)
      if (response.status !== 200) {
        throw ErrorMessages.ADDRESS_EDIT
      }
      dispatch(AuthActions.changeAddressFulfilled(values))
    } catch (error: any) {
      dispatch(AuthActions.changeAddressRejected(error))
      throw error?.toString()
    }
  }

export const updateDeviceId = () =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch(AuthActionsV2.updateDeviceIdPending())
      const response = await AuthServices.updateDeviceId({
        deviceId: await getFirebaseToken(),
        OS: 'web'
      })
      dispatch(AuthActionsV2.updateDeviceIdFulfilled(response.data.data.deviceId))
    } catch (error) {
      dispatch(AuthActionsV2.updateDeviceIdRejected(error as Error))
    }
  }
