import { api } from '@qirapagos/lib/config/api'
import { insertKeyEndpoint } from './utils'
import { IbodyPhone } from '@qirapagos/lib/interfaces/reduxInterfaces'
import { IBodyAddress } from '@qirapagos/lib/store/auth/types'
import { AuthState } from 'store/auth/types'
import { AxiosRequestConfig } from 'axios'
import { instance, ApiResponse } from '.'

const LOGIN = 'mu-me/login'
const NEW_PASSWORD = 'user/updatePassword'
const CREATE_LOGIN_LOG = 'auth/last-login'
const READ_LAST_LOGIN = 'auth/last-login'
const TWO_FACTOR_AUTH = 'twofactor/token?'
const TWO_FACTOR_VALIDATE = 'twofactor/validate'
const DELETE_ACCOUNT = 'company/unsubscribe'
const SUSPEND_ACCOUNT = 'company/suspension'
const CHANGE_EMAIL = 'client/email'
const CHANGE_PHONE = 'client/phone'
const GET_MY_DATA = 'user/client'
const PRIVACY_POLICIES = (clientId: string | null) => `client/${clientId}/privacy-policies`
const TERMS_AND_CONDITIONS = (clientId: string | null, type: 'full' | 'short') => {
  return `client/${clientId}/terms-and-conditions/${type}`
}

export const loginMulti = (
  user: any,
  device: string | null,
  token: string | null,
  keyEndpoints: string,
  idDevice: string
) => api.post(insertKeyEndpoint(LOGIN, keyEndpoints),
  {
    user: user.user,
    password: user.password,
    os: 'web',
    idDevice
  }, {
    headers: { device, token }
  })

export const forgotPasswordAsk = (email: string) => {
  const keyEndpoints = localStorage.getItem('keyendpoints')
  return api.post(insertKeyEndpoint('auth/forgot-password/ask', keyEndpoints as string), { email })
}

export const forgotPasswordValidate = (token: string) => {
  const keyEndpoints = localStorage.getItem('keyendpoints')
  return api.post(insertKeyEndpoint('auth/forgot-password/validate', keyEndpoints as string), { token })
}

export const forgotPasswordRecover = (password: string, token: string) => {
  const clientId = localStorage.getItem('clientId')
  return api.post(insertKeyEndpoint('auth/forgot-password/recover'),
    { token, password },
    { headers: { clientId } })
}

export const twoFactorAuthentication = (
  idChannel: number | null,
  email?: string,
  phone?: string
) => {
  const keyEndpoints = localStorage.getItem('keyendpoints')
  const clientId = localStorage.getItem('clientId')
  const token = localStorage.getItem('token')

  let url = `${TWO_FACTOR_AUTH}idClient=${clientId}&idChannel=${idChannel}`

  if (email) {
    url += `&email=${email}`
  }
  if (phone) {
    url += `&phone=${phone}`
  }

  return api.get(
    insertKeyEndpoint(url, keyEndpoints as string),
    {},
    { headers: { token } }
  )
}

export const newPassword = (oldPassword: string, newPassword: string) => {
  const token = localStorage.getItem('token')
  const keyEndpoints = localStorage.getItem('keyendpoints') as string
  return api.post(insertKeyEndpoint(NEW_PASSWORD, keyEndpoints), { oldPassword, newPassword },
    { headers: { token } }
  )
}

interface TwoFactorValidationResponse {
      message: string,
      data: {
        success: true,
        validationId: number,
      },
      error: false,
    }

export const twoFactorValidation = (code: string) => {
  const clientId = localStorage.getItem('clientId')
  const token2FA = localStorage.getItem('twoFactorAuthCode') as string
  const keyEndpoints = localStorage.getItem('keyendpoints') as string
  return api.post<TwoFactorValidationResponse>(
    insertKeyEndpoint(`${TWO_FACTOR_VALIDATE}`, keyEndpoints),
    { token: token2FA, code },
    { headers: { clientId } }
  )
}

export const unsubscribeCompany = async (reason?: string) => {
  const token = localStorage.getItem('token') as string
  const companyId = Number(localStorage.getItem('companyId') as string)
  return api.post(
    insertKeyEndpoint(DELETE_ACCOUNT),
    {
      reason,
      companyId,
      vendorId: null
    },
    { headers: { token } }
  )
}

export const suspendCompany = async (reason?: string) => {
  const token = localStorage.getItem('token') as string
  const companyId = Number(localStorage.getItem('companyId') as string)
  return api.post(
    insertKeyEndpoint(SUSPEND_ACCOUNT),
    {
      reason,
      companyId,
      vendorId: null
    },
    { headers: { token } }
  )
}

export const changeEmail = async (email?: string) => {
  const clientId = localStorage.getItem('clientId')
  const numberClientId = Number(clientId)
  const token = localStorage.getItem('token') as string
  return api.put(
    insertKeyEndpoint(`${CHANGE_EMAIL}/${numberClientId}`),
    {
      email
    },
    { headers: { token } }
  )
}

export const changePhone = async (bodyPhone?: IbodyPhone) => {
  const clientId = localStorage.getItem('clientId')
  const numberClientId = Number(clientId)
  const token = localStorage.getItem('token') as string
  return api.put(
    insertKeyEndpoint(`${CHANGE_PHONE}/${numberClientId}`),
    bodyPhone,
    { headers: { token } }
  )
}

export const createLoginLog = async () => {
  const clientId = localStorage.getItem('clientId')
  const token = localStorage.getItem('token') as string

  return api.post(
    insertKeyEndpoint(`${CREATE_LOGIN_LOG}/${clientId}`),
    {
      platform: 'web'
    },
    {
      headers: { token }
    }
  )
}

export const readLastLogin = async () => {
  const clientId = localStorage.getItem('clientId')
  const token = localStorage.getItem('token') as string

  return api.get(
    insertKeyEndpoint(`${READ_LAST_LOGIN}/${clientId}`),
    { platform: 'web' },
    { headers: { token } }
  )
}

export const changeAlias = async (alias?: string) => {
  const clientId = localStorage.getItem('clientId')
  const token = localStorage.getItem('token') as string
  return api.put(
    insertKeyEndpoint(`account/alias/${clientId}`),
    { alias },
    { headers: { token } }
  )
}

export const termsAndConditions = (
  type: 'full' | 'short'
) => {
  const clientId = localStorage.getItem('clientId')
  const token = localStorage.getItem('token')

  return api.get(
    insertKeyEndpoint(TERMS_AND_CONDITIONS(clientId, type)),
    {},
    { headers: { token } }
  )
}

export const privacyPolicies = (
) => {
  const clientId = localStorage.getItem('clientId')
  const token = localStorage.getItem('token')

  return api.get(
    insertKeyEndpoint(PRIVACY_POLICIES(clientId)),
    {},
    { headers: { token } }
  )
}

export const getMyData = (
  clientId: number | null | string,
  token: string | null,
  keyEndpoints: string
) =>
  api.get(
    insertKeyEndpoint(`${GET_MY_DATA}/${clientId}`, keyEndpoints),
    {},
    { headers: { token } }
  )

export const changeAddress = async (bodyAddress?: IBodyAddress) => {
  const clientId = localStorage.getItem('clientId')
  const numberClientId = Number(clientId)
  const token = localStorage.getItem('token') as string
  return api.put(
    insertKeyEndpoint(`client/${numberClientId}`),
    bodyAddress,
    { headers: { token } }
  )
}

interface UpdateDeviceIdResponse {
  deviceId: AuthState['deviceId']
}

export const updateDeviceId = async (data: AxiosRequestConfig['data']) => {
  return instance.post<ApiResponse<UpdateDeviceIdResponse>>(insertKeyEndpoint('user-app/device'), data)
}
